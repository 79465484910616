import { LazyMotion, m, useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { PageContainer } from '@/components';
import { CheckIcon } from './CheckIcon';
import { RichtextItemBlokProps } from '../RichtextItem/RichtextItem';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

const loadFeatures = () =>
  import('../../helpers/framerFeatures').then((res) => res.default);

export interface AnimatedBackgroundSectionBlokProps extends SbBlokData {
  background: Asset;
  backgroundPosition?: 'top' | 'center' | 'bottom' | '';
  bulletsIcons?: 'enabled' | 'disabled' | '';
  bulletsTextAlignment?: 'left' | 'center' | '';
  contentCardBackgroundColor?:
    | 'none'
    | 'GSpink-400'
    | 'GSgreen-200'
    | 'GSyellow-300'
    | '';
  contentCardPlacement: 'left' | 'right' | '';
  bullets: Array<RichtextItemBlokProps>;
  cta: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  title: string;
}

interface AnimatedBackgroundSectionProps {
  blok: AnimatedBackgroundSectionBlokProps;
}

const AnimatedBackgroundSection = ({
  blok,
}: AnimatedBackgroundSectionProps) => {
  const {
    background,
    backgroundPosition = 'center',
    bulletsIcons = 'enabled',
    bulletsTextAlignment = 'left',
    contentCardBackgroundColor = 'GSgreen-200',
    contentCardPlacement = 'right',
    bullets = [],
    cta: [cta] = [],
    id,
    title,
  } = blok;
  const sectionWidthProviderRef = useRef<HTMLDivElement>(null);
  const contentHeightProviderRef = useRef<HTMLDivElement>(null);

  const targetRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  const [maxHeight, setMaxHeight] = useState(0);
  const [sectionWidth, setSectionWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const sourceHeightDiv = contentHeightProviderRef.current;

    const updateHeight = () => {
      if (sourceHeightDiv) {
        setMaxHeight(sourceHeightDiv.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    if (sourceHeightDiv) {
      resizeObserver.observe(sourceHeightDiv);
    }

    return () => {
      if (sourceHeightDiv) {
        resizeObserver.unobserve(sourceHeightDiv);
      }
    };
  }, [contentHeightProviderRef]);

  useEffect(() => {
    const sourceWidthDiv = sectionWidthProviderRef.current;

    const updateWidth = () => {
      if (sourceWidthDiv) {
        setSectionWidth(sourceWidthDiv.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (sourceWidthDiv) {
      resizeObserver.observe(sourceWidthDiv);
    }

    return () => {
      if (sourceWidthDiv) {
        resizeObserver.unobserve(sourceWidthDiv);
      }
    };
  }, [sectionWidthProviderRef]);

  const width = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [
      `${(sectionWidth * 100) / windowWidth}%`,
      '100%',
      `${(sectionWidth * 100) / windowWidth}%`,
    ],
  );

  return (
    <section {...storyblokEditable(blok)} className="relative" id={id}>
      <div className="w-full absolute py-8 sm:py-12 lg:py-16">
        <LazyMotion features={loadFeatures}>
          <m.div
            ref={targetRef}
            style={{
              width,
              height: maxHeight,
            }}
            className="mx-auto overflow-hidden rounded-[32px] hidden sm:block"
          >
            <StoryblokImage
              blok={background}
              lazyLoading={false}
              className={cx('w-full h-full object-cover', {
                'object-top': backgroundPosition === 'top',
                'object-center': backgroundPosition === 'center',
                'object-bottom': backgroundPosition === 'bottom',
              })}
            />
          </m.div>
        </LazyMotion>
      </div>
      <PageContainer className="!gap-y-0 sm:!gap-y-0 lg:!gap-y-0 relative z-10">
        <div className="w-full grid grid-cols-6 sm:grid-cols-12">
          <div
            ref={sectionWidthProviderRef}
            className="col-span-10 col-start-2"
          />
        </div>
        <div className="w-full flex flex-col gap-y-7 sm:gap-y-10 lg:gap-y-14">
          <div
            className="w-full grid grid-cols-6 sm:grid-cols-12 sm:p-[42px]"
            ref={contentHeightProviderRef}
          >
            <div
              className={cx(
                'col-span-6 sm:col-span-7 lg:col-span-4 p-6 lg:p-8 rounded-2xl sm:rounded-[32px] flex flex-col gap-y-4',
                {
                  'col-start-1 sm:col-start-5 lg:col-start-8':
                    contentCardPlacement === 'right' ||
                    contentCardPlacement === '',
                  'col-start-1 sm:col-start-2 lg:col-start-2':
                    contentCardPlacement === 'left',
                  'bg-GSpink/400': contentCardBackgroundColor === 'GSpink-400',
                  'bg-GSgreen/200':
                    contentCardBackgroundColor === 'GSgreen-200' ||
                    contentCardBackgroundColor === '',
                  'bg-GSyellow/300':
                    contentCardBackgroundColor === 'GSyellow-300',
                },
              )}
            >
              <div className="text-GSbase/black text-center text-2xl sm:leading-[48px] lg:text-4xl font-raleway font-extrabold">
                {title}
              </div>
              {bullets &&
                bullets.length &&
                bullets.map((bullet) => (
                  <div key={bullet._uid} className="flex gap-x-4">
                    {(bulletsIcons === 'enabled' || bulletsIcons === '') && (
                      <CheckIcon className="w-8 h-8 shrink-0" />
                    )}
                    <div
                      className={cx('text-base', {
                        'text-left':
                          bulletsTextAlignment === 'left' ||
                          bulletsTextAlignment === '',
                        'text-center': bulletsTextAlignment === 'center',
                      })}
                    >
                      <StoryblokComponent blok={bullet} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {cta && (
            <div className="hidden col-span-full sm:flex sm:justify-center">
              <StoryblokComponent blok={cta} />
            </div>
          )}
        </div>
      </PageContainer>
    </section>
  );
};

export default AnimatedBackgroundSection;
