import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

const AnimatedCounter = dynamic(
  () => import('../../components/AnimatedCounter/AnimatedCounter'),
  {
    loading: () => <div />,
  },
);

export interface StatisticItemBlokProps extends SbBlokData {
  title: string;
  description: string;
  icon: Asset;
}

interface StatisticItemProps {
  blok: StatisticItemBlokProps;
  theme?: 'default' | 'ideal' | '';
}

const StatisticItem = ({ blok, theme }: StatisticItemProps) => {
  const { description, icon, title } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('flex flex-col ', {
        'sm:gap-2': theme !== 'ideal',
        'sm:gap-3': theme === 'ideal',
      })}
    >
      {icon && (
        <StoryblokImage
          blok={blok.icon}
          className="self-center max-h-12 max-w-[48px] sm:max-h-14 sm:max-w-[56px] lg:max-h-16 lg:max-w-[64px]"
        />
      )}
      {title && (
        <div
          className={cx(
            'font-raleway text-[28px] leading-10 font-extrabold text-center sm:leading-[48px] lg:leading-[56px]',
            {
              'text-GSblue/500 [overflow-wrap:anywhere] sm:text-[32px] lg:text-[40px]':
                theme !== 'ideal',
              'text-GSbase/white sm:text-[40px] lg:text-5xl': theme === 'ideal',
            },
          )}
        >
          {theme === 'ideal' ? <AnimatedCounter text={title} /> : title}
        </div>
      )}
      {description && (
        <div
          className={cx('text-center font-medium text-sm sm:text-base', {
            'text-GSdeep/500 [overflow-wrap:anywhere]': theme !== 'ideal',
            'text-GSbase/white sm:leading-5 lg:text-2xl lg:leading-7 self-center':
              theme === 'ideal',
          })}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default StatisticItem;
